import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import Mail from '../components/Mail';
import HeroImage from '../svg/HeroImage';

import comcomGif from '../assets/comcom-low.gif'

const Index = () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Software Consulting
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Interactive web & mobile development
          </p>
          <p className="mt-4 text-gray-600">Boost your business with our expertise in interactive web application, backend & mobile app development.</p>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="expertise" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Expertise</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Elixir</p>
              <p className="mt-4">
                Extensive expertise in Elixir development, having built multiple production applications with the Phoenix Framework.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Typescript</p>
              <p className="mt-4">
                We're fully proficient in modern Typescript and can develop and maintain production quality web frontends with it.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Haskell</p>
              <p className="mt-4">
                Got something more challenging? We love Haskell and use it to build top quality production applications.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <div className="container mx-auto text-center mb-6">
      <h2 className="text-3xl lg:text-5xl font-semibold">Past Projects</h2>
    </div>
    <SplitSection
      id="projects"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Comcom</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Comcom was a real-time discussion platform for news sites. It was deployed on one of the largest Finnish news sites.
          </p>
        </div>
      }
      secondarySlot={
        <div class="flex justify-center">
          <img src={comcomGif} alt="Comcom Platform" />
        </div>
      }
    />
    <section id="contact" className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Ready to grow your business?</h3>
      <p className="mt-8 text-xl font-light">
        Contact us for a quote for building your idea.
      </p>
      <p className="mt-8">
        <Mail />
      </p>
    </section>
  </Layout>
);

export default Index;
